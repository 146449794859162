import { EventEmitter } from 'typed-ts-events';
import { SDN_BASE } from '../constants';

export class VideoManager extends EventEmitter<Events> {

    private readonly quality: Array<string>;
    private readonly videos: Array<string>;
    private currentQualityIndex = 0;
    private videoData: Array<Record<number, HTMLVideoElement>> = [];
    private readonly year: string;
    private readonly orientation: 'horizontal' | 'vertical';
    private readonly isPhone: boolean;

    constructor(quality: Array<string>, videos: Array<string>, year: string, orientation: 'horizontal' | 'vertical', isPhone: boolean) {
        super();

        this.year = year;
        this.quality = isPhone ? [quality[0]] : quality;
        this.videos = videos;
        this.orientation = orientation;
        this.isPhone = isPhone;

        this.on('changeVideos', data => {
            console.log(`Loaded video ${this.videos[data.videoIndex]}, quality is ${this.quality[data.quality]}`);
        });

        this.restoreQuality();
    }

    public loadAll(): void {
        this.startLoad();
    }

    public loadOne(fileName: string): Promise<unknown> {
        const index = this.videos.indexOf(fileName);

        if (index === -1) {
            throw new Error('Wrong file name!');
        }

        return this.downloadOneVideo(index)
            .then(({ quality }) => {
                const maxQuality = this.quality.length - 1;

                if (quality !== maxQuality) {
                    return this.downloadOneVideo(index, maxQuality);
                }
            });
    }

    private downloadOneVideo(index: number, quality?: number): Promise<VideoLoadData> {
        if (this.videoData[index]) {
            if (quality != null) {
                if (this.videoData[index][quality]) {
                    return Promise.resolve({
                        quality,
                        targetQuality: this.currentQualityIndex,
                        video: this.videoData[index][quality],
                        videoIndex: index
                    });
                }
            } else {
                const loadedQuality = Math.max(...Object.keys(this.videoData).map(Number));
                return Promise.resolve({
                    quality: loadedQuality,
                    targetQuality: this.currentQualityIndex,
                    videoIndex: index,
                    video: this.videoData[index][loadedQuality]
                });
            }
        }

        return this.loadVideo(quality ?? this.currentQualityIndex, index)
            .then((data: VideoLoadData) => {
                this.videoData[index] = { [data.quality]: data.video };
                this.currentQualityIndex = data.targetQuality;
                this.saveQuality(this.currentQualityIndex);
                this.trigger('changeVideos', { ...data });

                return data;
            });
    }

    public getReadyState(): Array<VideoManagerStateItem> {
        return this.videos.map((_, index) => {
            const videoData = this.videoData[index];
            const keys = Object.keys(videoData || {}).map(Number);

            if (keys.length === 0) {
                return {
                    index,
                    ready: false
                };
            }

            return {
                index,
                ready: true,
                video: videoData[Math.max(...keys)]
            };
        });
    }

    private startLoad(): void {
        const afterLoadVideo = () => this.startLoad();

        if (this.videoData.filter(Boolean).length >= this.videos.length) {
            const maxQuality = this.quality.length - 1;
            const item = this.videoData.find(item => !item[maxQuality]);

            if (item == null) {
                return void 0;
            }

            const index = this.videoData.indexOf(item as VideoLoadData);

            this.downloadOneVideo(index, maxQuality)
                .then(afterLoadVideo);

            return void 0;
        }

        const toLoad = this.videos.map((name, index) => this.videoData[index] ? null : index)
            .find(item => item != null) ?? 0;

        this.downloadOneVideo(toLoad)
            .then(afterLoadVideo);
    }

    private loadVideo(quality: number, videoIndex: number): Promise<VideoLoadData> {
        const loadStartTime = Date.now();
        return fetch(this.buildVideoSrc(quality, videoIndex))
            .then(this.createLoadCallback(videoIndex, quality))
            .then(blob => {
                const src = window.URL.createObjectURL(blob);
                const video = document.createElement('video');

                video.muted = true;
                video.autoplay = true;
                video.defaultMuted = true;
                video.setAttribute('playsInline', '');
                video.src = src;

                return new Promise((resolve) => {
                    const loadHandler = () => {
                        const loadEndTime = Date.now();
                        const duration = video.duration * 1000;
                        const loadTime = loadEndTime - loadStartTime;
                        const targetQuality = loadTime < duration - duration * 0.2
                            ? Math.min(quality + 1, this.quality.length - 1)
                            : loadTime > duration + duration * 0.2
                                ? Math.max(0, quality - 1)
                                : quality;

                        resolve({ video, quality, targetQuality, videoIndex });
                        video.removeEventListener('loadedmetadata', loadHandler, false);
                    };
                    video.addEventListener('loadedmetadata', loadHandler, false);
                });
            });
    }

    private createLoadCallback(index: number, quality: number) {
        return (response: Response) => new Promise((resolve, reject) => {
            if (!response.body) {
                reject(new Error('Response has no body!'));
                return void 0;
            }

            const type = response.headers.get('Content-Type') as string;
            const contentLength = Number(response.headers.get('Content-Length'));
            const reader = response.body.getReader();
            const result = new Uint8Array(contentLength);
            let loadedContentLength = 0;

            const loop = ({ done, value }: ReadableStreamReadResult<Uint8Array>) => {
                if (done) {
                    resolve(new Blob([result], { type }));
                    return void 0;
                }

                result.set(value!, loadedContentLength);
                loadedContentLength += value!.length;

                const loadProgress = loadedContentLength / contentLength;
                this.trigger('loadVideoProgress', { loadProgress, index, quality });

                reader.read().then(loop, reject);
            };

            reader.read().then(loop, reject);
        });
    }

    private saveQuality(quality: number): void {
        try {
            localStorage.setItem(`video_quality_${this.year}`, String(quality));
        } catch (e) {
        }
    }

    private restoreQuality(): void {
        try {
            const raw = localStorage.getItem(`video_quality_${this.year}`);

            if (raw == null) {
                return void 0;
            }

            const quality = Number(raw);

            if (quality >= 0 && quality <= this.quality.length - 1) {
                this.currentQualityIndex = quality;
            }
        } catch (e) {
        }
    }

    private buildVideoSrc(quality: number, videoIndex: number): string {
        return [
            SDN_BASE,
            !this.isPhone || this.orientation == 'horizontal' ? `${this.quality[quality]}/` : 'v/',
            `${this.videos[videoIndex]}.mp4`
        ].join('');
    }
}

export type Events = {
    changeVideos: VideoLoadData;
    loadVideoProgress: { index: number; loadProgress: number; quality: number }
}

export type VideoLoadData = {
    video: HTMLVideoElement;
    quality: number;
    videoIndex: number;
    targetQuality: number;
}

export type LoadedItem = {
    ready: true;
    video: HTMLVideoElement;
};

export type LoadingItem = {
    ready: false;
}

export type VideoManagerStateItem = { index: number } & (LoadingItem | LoadedItem);
