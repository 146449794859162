import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {PageData, welcomeModel} from '../../models/WelcomeModel';
import {Events, VideoManager, VideoManagerStateItem} from '../../services/VideoManager';
import {BackgroundVideo} from '../../components/BackgroundVideo/BackgroundVideo';
import './welcome.styl';
import {RouteComponentProps} from 'react-router-dom';
import {Logo} from '../../components/Logo/Logo';
import {Info} from '../../components/Info/Info';
import {Menu} from '../../components/Menu/Menu';
import {DataItem} from '../../models/DataModel';
import classNames from 'classnames';
import {Project} from '../../components/Project/Project';
import requestAnimationFrameDebounce from '../../utils/requestAnimationFrameDebounce';
import {resizeEffect} from '../../utils/resizeEffect';
import {propEq} from 'ramda';
import {getPage} from '../../utils/getPage';
import {makeUrl} from '../../utils/makeUrl';
import {IS_PHONE} from '../../constants';
import Hammer, {DIRECTION_LEFT, DIRECTION_RIGHT} from 'hammerjs';


const getOrientation = (): 'horizontal' | 'vertical' =>
    !IS_PHONE
        ? 'horizontal'
        : document.body.clientWidth > document.body.clientHeight
        ? 'horizontal'
        : 'vertical';

export const Welcome: FC<RouteComponentProps<MatchParams>> = ({match}) => {
    const pageName: string | null = match.params.video == null ? null : match.params.video;

    const [manager, setVideoManager] = useState<VideoManager | undefined>(undefined);
    const [meta, setMeta] = useState<Pick<PageData, 'meta' | 'years'> | undefined>(undefined);
    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [loadingInProgress, setLoadingState] = useState<boolean>(true);
    const [activeVideoIndex, setActiveVideoIndex] = useState<number | undefined>(undefined);
    const [pages, setPages] = useState<Array<PageInfo>>([]);
    const [activePageIndex, setPageMode] = useState<number | null>(null);
    const [years, setYears] = useState<Array<string>>([]);
    const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>(getOrientation());

    const pageRef = useRef<HTMLDivElement>(null);

    useEffect(resizeEffect(700, () => {
        if (IS_PHONE) {
            setOrientation(getOrientation());
        }
    }), []);

    useEffect(() => {
        window.addEventListener('resize', requestAnimationFrameDebounce(() => {
            const html = document.documentElement;

            const loop = () => {
                if (html.clientHeight !== window.innerHeight) {
                    html.style.height = `${window.innerHeight}px`;
                    requestAnimationFrame(() => {
                        html.style.height = '';
                    });
                }
            };

            const arr: Array<any> = [];
            for (let i = 0; i < 50; i++) {
                arr.push(loop);
            }

            const apply = function () {
                if (!arr.length) {
                    return void 0;
                }
                arr.pop()();
                requestAnimationFrame(apply);
            };

            setTimeout(apply, 150);
        }), false);

    }, []);

    useEffect(() => {
        const index = pages.find(propEq('projectName', pageName))?.index ?? null;
        if (index) {
            setActiveVideoIndex(index);
        }
        setPageMode(index);
    }, [pageName, pages]);

    useEffect(() => {
        const active = pages.find(propEq('ready', true))?.index;

        if (active == null) {
            return void 0;
        }

        if (activeVideoIndex === undefined) {
            setActiveVideoIndex(active);
        }
        if (loadingInProgress && active != null) {
            setLoadingState(false);
        }
    }, [pages, activeVideoIndex, loadingInProgress]);

    useEffect(() => {
        const firstReady = pages[0]?.ready ?? false;

        if (!firstReady && pageName == null) {
            setLoadingState(true);
        }
    }, [pageName, pages[0]?.ready]);

    useEffect(() => {
        if (!manager || !meta) {
            return void 0;
        }

        setYears(meta.years);
        const onVideoChange = () => {
            const pages = manager.getReadyState().map((state) => ({
                ...state,
                ...meta.meta[state.index]
            }));
            setPages(pages);
        };

        manager.on('changeVideos', onVideoChange);
        onVideoChange();

        return () => manager.off('changeVideos', onVideoChange);
    }, [manager, meta]);

    useEffect(() => {
        if (!manager || !loadingInProgress) {
            return void 0;
        }

        const onVideoLoadProgress = (event: Events['loadVideoProgress']) => {
            setLoadingProgress(event.loadProgress);
            if (event.loadProgress >= 1) {
                manager.off('loadVideoProgress', onVideoLoadProgress);
            }
        };

        manager.on('loadVideoProgress', onVideoLoadProgress);
        return () => {
            manager.off('loadVideoProgress', onVideoLoadProgress);
        }
    }, [manager, loadingInProgress]);

    useEffect(() => {
        setPages([]);
        setLoadingState(true);
        setActiveVideoIndex(undefined);

        const promise = welcomeModel.openPage(match.params.year, match.params.playListId, orientation);
        promise.then(({ manager, ...props }) => {
            setMeta(props);
            setVideoManager(manager);
        });

        return () => {
            promise
                .then(({ manager }) => {
                    manager.off('changeVideos');
                    manager.off('loadVideoProgress');
                });
        };
    }, [match.params.year, orientation]);

    useEffect(() => {
        if (!manager) {
            return void 0;
        }
        if (pageName) {
            welcomeModel.loadVideo(manager, pageName, match.params.year, match.params.playListId)
                .then(() => manager.loadAll());
        } else {
            manager.loadAll();
        }
    }, [manager]);

    const applyVideo = useCallback((index: number) => {
        const item = pages[index];

        if (!item || !item.ready) {
            return void 0;
        }

        if (activeVideoIndex != index) {
            setActiveVideoIndex(index);
        }
        item.video.currentTime = 0;
    }, [setActiveVideoIndex, pages, activeVideoIndex]);

    const onHoverIn = useCallback((index: number) =>
        pageName == null ? applyVideo(index) : void 0, [applyVideo, pageName]);

    const onHoverOut = useCallback(() => void 0, []);

    const onVideoEnded = useCallback(() => {
        if (activeVideoIndex == null) {
            return void 0;
        }

        if (pageName != null) {
            const active = pages.find(propEq('fileName', pageName));
            if (active?.ready) {
                active.video.currentTime = 0;
                active.video.play();
            }
            return void 0;
        }

        const next = getPage('next', activeVideoIndex, pages);
        const firstReady = getPage('firstReady', activeVideoIndex, pages);

        const active = pages[activeVideoIndex];
        if (next) {
            setActiveVideoIndex(next.index);
        } else if (firstReady) {
            setActiveVideoIndex(firstReady.index);
        } else {
            if (active.ready) {
                active.video.currentTime = 0;
                active.video.play();
            }
        }
    }, [activeVideoIndex, pages, pageName]);

    const index = activePageIndex ?? activeVideoIndex;
    const video = (index != null)
        ? (pages[index] as { video?: HTMLVideoElement })?.video
        : null;

    useEffect(() => {
        if (pageRef.current === null) {
            return void 0;
        }
        const hammer = new Hammer(pageRef.current);
        hammer.on("swipeleft", handleSwipeLeft);
        hammer.on("swiperight", handleSwipeRight);
        return () => {
            hammer.off("swipeleft", handleSwipeLeft);
            hammer.off("swiperight", handleSwipeRight);
        };
    }, [activeVideoIndex, pages]);

    const page = pages.find(item => item.projectName === pageName);

    const handleSwipeRight = useCallback((e: HammerInput) => {
        console.log("swipeRight", e);
        if (activeVideoIndex == null) {
            return void (0);
        }

        const previous = getPage('previous', activeVideoIndex, pages);
        if (previous) {
            setActiveVideoIndex(previous.index);
        }

    }, [activeVideoIndex, pages]);

    const handleSwipeLeft = useCallback((e: HammerInput) => {
        console.log("swipeLeft", e);
        if (activeVideoIndex == null) {
            return void (0);
        }

        const next = getPage('next', activeVideoIndex, pages);
        if (next) {
            setActiveVideoIndex(next.index);
        }

    }, [activeVideoIndex, pages]);

    const href = makeUrl({
        year: years.includes('20') && page === null ? undefined : match.params.year,
        playList: match.params.playListId
    });

    return (
        <div ref={pageRef}
             className={classNames('welcome-page', {'loading': loadingInProgress}, {'welcome-page__page-mode': pageName != null})}>
            {page != null
                ? null
                : loadingInProgress ? null : <Info/>
            }
            <Logo color={page != null ? 'dark' : 'light'}
                  href={href}
                  isLoading={loadingInProgress}
                  loadProgress={loadingProgress}
            />
            {video != null
                    ?
                    <BackgroundVideo className={'welcome-page__background'}
                                     onVideoEnded={onVideoEnded}
                                     video={video}/>
                    : null
                }
                {!loadingInProgress && page != null
                    ? <Project title={page.pageTitle}
                               text={page.pageText}
                               ytid={page.youtubeVideoId}
                               ytplid={page.youtubePlayListId}
                               date={page.pageDateText}
                               linkUrl={page.pageLinkUrl}
                               fb={page.facebookId}/>
                    : null
                }
                {!loadingInProgress && video != null
                    ? <Menu color={pageName != null ? 'dark' : 'light'}
                            page={pageRef.current}
                            video={video}
                            pages={pages}
                            activeVideoIndex={page?.index ?? activeVideoIndex as number}
                            onHoverIn={onHoverIn}
                            onHoverOut={onHoverOut}
                            years={years}
                            playList={match.params.playListId}
                            activeYear={match.params.year ?? '20'}
                    />
                    : null
                }
            </div>
    );
};

type MatchParams = {
    video?: string;
    year?: string;
    playListId?: string;
}

export type PageInfo = DataItem & VideoManagerStateItem;
