import React, { FC } from 'react';
import './logo.styl';
import cn from 'classnames';
import { Link } from 'react-router-dom';

export const Logo: FC<Props> = ({ color, isLoading, loadProgress, href }) => (
    <Link className={cn('logo', isLoading ? 'loading' : null)}
          to={href}>
        <svg className={cn(isLoading ? 'dark' : color)} viewBox="0 0 107 53" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <mask id={'logo-mask'} x={0} y={0} width={107} height={53}>
                <path d="M0.327,8.429h5.195v2.254h0.065C6,9.921,6.632,9.279,7.482,8.756s1.84-0.784,2.973-0.784c2.331,0,3.964,0.926,4.9,2.777
	c1.176-1.851,2.907-2.777,5.194-2.777c1.111,0,2.042,0.202,2.794,0.604c0.751,0.403,1.356,0.926,1.813,1.568
	c0.458,0.642,0.789,1.362,0.996,2.156c0.207,0.795,0.311,1.574,0.311,2.336v10.062h-5.358v-8.886c0-2.287-0.773-3.431-2.32-3.431
	c-0.871,0-1.541,0.343-2.009,1.029s-0.702,1.519-0.702,2.499v8.788h-5.358v-9.278c0-2.026-0.773-3.039-2.32-3.039
	c-0.828,0-1.481,0.332-1.961,0.997c-0.479,0.665-0.719,1.498-0.719,2.499v8.821H0.327V8.429z"/>
                <path d="M47.11,7.972c2.331,0,4.062,0.849,5.195,2.548h0.065V8.429h5.064v14.735c0,3.093-0.849,5.428-2.548,7.008
	c-1.699,1.579-4.041,2.368-7.024,2.368c-0.719,0-1.459-0.06-2.221-0.179c-0.763-0.12-1.514-0.289-2.254-0.507
	c-0.741-0.218-1.443-0.485-2.108-0.8c-0.665-0.316-1.258-0.67-1.78-1.062l2.679-3.822c1.568,1.372,3.365,2.058,5.391,2.058
	c1.524,0,2.673-0.408,3.446-1.225s1.16-1.922,1.16-3.316v-0.98h-0.098c-1.111,1.437-2.756,2.156-4.933,2.156
	c-1.198,0-2.277-0.217-3.234-0.653c-0.958-0.436-1.775-1.029-2.45-1.78c-0.676-0.752-1.193-1.639-1.552-2.663
	c-0.359-1.024-0.539-2.113-0.539-3.267s0.174-2.249,0.523-3.283s0.86-1.938,1.536-2.712c0.674-0.773,1.491-1.389,2.45-1.846
	C44.834,8.201,45.912,7.972,47.11,7.972z M44.562,16.466c0,0.566,0.092,1.1,0.278,1.6c0.185,0.501,0.441,0.942,0.767,1.323
	c0.327,0.381,0.724,0.681,1.193,0.899c0.468,0.218,0.996,0.326,1.585,0.326c1.197,0,2.156-0.403,2.875-1.209
	c0.37-0.392,0.642-0.843,0.817-1.356c0.174-0.511,0.261-1.04,0.261-1.584c0-0.544-0.087-1.073-0.261-1.585
	c-0.175-0.512-0.447-0.964-0.817-1.356c-0.741-0.784-1.699-1.176-2.875-1.176s-2.108,0.392-2.794,1.176
	C44.905,14.31,44.562,15.29,44.562,16.466z"/>
                <path d="M66.908,8.429v8.821c0,2.309,0.806,3.463,2.418,3.463c0.914,0,1.622-0.337,2.123-1.013c0.501-0.674,0.752-1.503,0.752-2.483
	V8.429h5.358v16.27h-5.162v-2.254h-0.065c-0.392,0.762-1.035,1.405-1.927,1.927c-0.893,0.523-1.884,0.784-2.973,0.784
	c-1.002,0-1.879-0.175-2.63-0.523c-0.751-0.349-1.367-0.822-1.845-1.421c-0.479-0.599-0.839-1.306-1.078-2.123
	c-0.24-0.817-0.36-1.683-0.36-2.598V8.429H66.908z"/>
                <path d="M80.923,16.629c0-1.285,0.218-2.466,0.654-3.545s1.04-1.998,1.814-2.761c0.773-0.762,1.699-1.356,2.777-1.78
	c1.078-0.425,2.259-0.637,3.545-0.637c1.219,0,2.331,0.202,3.333,0.605c1.001,0.403,1.862,0.991,2.581,1.764
	s1.274,1.704,1.666,2.793c0.392,1.09,0.588,2.32,0.588,3.692c0,0.305,0,0.561,0,0.767c0,0.207-0.011,0.376-0.033,0.507H86.053
	c0.044,0.436,0.179,0.839,0.408,1.209c0.229,0.371,0.517,0.697,0.866,0.98c0.348,0.284,0.751,0.501,1.208,0.654
	c0.458,0.153,0.926,0.229,1.405,0.229c1.589,0,2.788-0.566,3.593-1.699l3.725,2.353c-1.591,2.308-4.051,3.463-7.384,3.463
	c-1.285,0-2.472-0.202-3.561-0.604c-1.089-0.403-2.037-0.975-2.842-1.715c-0.806-0.74-1.433-1.645-1.879-2.712
	C81.146,19.123,80.923,17.936,80.923,16.629z M86.053,14.702h6.894c0-0.915-0.272-1.661-0.817-2.238s-1.34-0.866-2.385-0.866
	c-0.501,0-0.969,0.081-1.405,0.245s-0.817,0.387-1.143,0.67c-0.327,0.284-0.588,0.61-0.784,0.981
	C86.216,13.864,86.096,14.266,86.053,14.702z"/>
                <path d="M106.7,24.699h-5.423V0h5.423V24.699z"/>
                <path
                    d="M13.787,35.532l3.431,10.553h0.098l3.431-10.553h5.717l-6.404,16.27h-5.782l-6.403-16.27H13.787z"/>
                <path d="M27.508,47.097c0-3.659,3.484-5.488,10.454-5.488v-0.261c0-0.762-0.272-1.345-0.817-1.748
	c-0.545-0.403-1.253-0.605-2.124-0.605c-1.459,0-2.799,0.555-4.018,1.667l-2.712-2.908c1.938-1.829,4.356-2.744,7.252-2.744
	c2.679,0,4.595,0.681,5.75,2.042c1.154,1.362,1.732,3.545,1.732,6.55v8.201h-4.9V50.07h-0.098c-0.392,0.654-1.012,1.171-1.862,1.552
	s-1.764,0.572-2.745,0.572c-0.762,0-1.503-0.104-2.221-0.311s-1.351-0.523-1.895-0.947c-0.544-0.425-0.98-0.958-1.306-1.6
	C27.671,48.692,27.508,47.946,27.508,47.097z M32.441,46.933c0,0.566,0.229,0.986,0.686,1.258c0.458,0.272,1.001,0.408,1.633,0.408
	c1.067,0,1.895-0.294,2.483-0.882s0.882-1.405,0.882-2.45v-0.556H37.44C34.108,44.711,32.441,45.453,32.441,46.933z"/>
                <path d="M47.012,35.532h5.195v2.254h0.065c0.392-0.762,1.024-1.405,1.895-1.927c0.871-0.523,1.862-0.784,2.973-0.784
	c1.024,0,1.905,0.175,2.646,0.523c0.741,0.349,1.356,0.823,1.846,1.421c0.49,0.599,0.854,1.306,1.094,2.123
	c0.239,0.817,0.36,1.683,0.36,2.598v10.062h-5.391v-8.887c0-2.287-0.806-3.43-2.418-3.43c-0.914,0-1.622,0.338-2.123,1.012
	c-0.501,0.676-0.752,1.503-0.752,2.483v8.821h-5.391L47.012,35.532L47.012,35.532z"/>
                <path d="M66.32,43.601c0-1.306,0.234-2.488,0.703-3.545c0.468-1.056,1.105-1.96,1.911-2.712c0.805-0.751,1.758-1.328,2.859-1.732
	c1.1-0.403,2.281-0.604,3.545-0.604c1.285,0,2.477,0.202,3.577,0.604c1.1,0.404,2.053,0.981,2.859,1.732
	c0.806,0.752,1.443,1.656,1.911,2.712c0.468,1.057,0.702,2.238,0.702,3.545s-0.234,2.494-0.702,3.562
	c-0.468,1.067-1.105,1.987-1.911,2.76s-1.759,1.367-2.859,1.78c-1.1,0.414-2.292,0.621-3.577,0.621
	c-1.263,0-2.445-0.213-3.545-0.637c-1.1-0.425-2.058-1.018-2.876-1.78c-0.817-0.762-1.453-1.677-1.911-2.744
	C66.548,46.095,66.32,44.908,66.32,43.601z M71.515,43.601c0,0.567,0.086,1.106,0.261,1.617c0.174,0.512,0.43,0.959,0.767,1.34
	s0.746,0.686,1.225,0.915s1.013,0.343,1.601,0.343s1.122-0.114,1.601-0.343s0.887-0.534,1.225-0.915
	c0.337-0.381,0.593-0.827,0.767-1.34c0.174-0.511,0.261-1.05,0.261-1.617c0-0.566-0.092-1.1-0.278-1.601
	c-0.185-0.501-0.447-0.936-0.784-1.306c-0.338-0.37-0.747-0.67-1.225-0.899c-0.48-0.229-1.013-0.343-1.601-0.343
	s-1.122,0.109-1.601,0.326c-0.479,0.218-0.882,0.518-1.209,0.899c-0.326,0.381-0.577,0.822-0.751,1.323
	C71.601,42.501,71.515,43.035,71.515,43.601z"/>
                <path
                    d="M91.573,35.532l3.431,10.553h0.098l3.431-10.553h5.717l-6.404,16.27h-5.782l-6.403-16.27H91.573z"/>
                <path d="M30.579,8.429h5.358v16.27h-5.358V8.429z M33.258,6.175c1.696,0,3.071-1.317,3.071-2.941s-1.375-2.941-3.071-2.941
	s-3.071,1.317-3.071,2.941S31.562,6.175,33.258,6.175z"/>
                <path d="M0.392,35.532H5.75v16.27H0.392V35.532z M3.071,33.278c1.696,0,3.071-1.317,3.071-2.941s-1.375-2.941-3.071-2.941
	C1.375,27.396,0,28.713,0,30.337S1.375,33.278,3.071,33.278z"/>
            </mask>
            {isLoading && loadProgress != undefined
                ? <rect className={'progress'} x={0} y={0} width={107} height={53} mask='url(#logo-mask)'
                        style={{ width: `${loadProgress * 100}%` }}/>
                : null}
            <rect className={'body'} x={0} y={0} width={107} height={53} mask='url(#logo-mask)'/>
        </svg>
    </Link>
);

type Props = {
    href: string;
    color: string;
    isLoading?: boolean;
    loadProgress?: number;
}

