export const makeUrl = ({ year, video, playList }: Props = {}) => '/' + ([
    video || year ? year ?? '20' : null,
    video ? video : null,
    playList ? `playList/${playList}` : null
].filter(Boolean).join('/'));

type Props = {
    year?: string;
    video?: string;
    playList?: string;
}
