import React, { createRef, FC, useCallback, useEffect, useState } from 'react';
import './backgroundVideo.styl';
import cn from 'classnames';
import { HistoryList } from '../../services/HistoryList';


export const BackgroundVideo: FC<Props> = ({ video, onVideoEnded, className }) => {
    const containerRef = createRef<HTMLDivElement>();
    const onContextMenu = useCallback((event) => event.preventDefault(), []);
    const [root, setRootElement] = useState<HTMLDivElement | null>();
    const [videoList] = useState<HistoryList<HTMLVideoElement>>(new HistoryList(2));

    useEffect(() => {
        videoList.push(video);
        return () => {
            setTimeout(() => {
                if (video !== videoList.last() && video.parentElement) {
                    video.parentElement.removeChild(video);
                }
            }, 300);
        };
    }, [video, videoList]);

    useEffect(() => {
        const tag = containerRef.current;

        if (!tag) {
            return void 0;
        }

        if (tag !== root) {
            setRootElement(tag);
        }

    }, [root, containerRef]);

    useEffect(() => {
        if (!root) {
            return void 0;
        }

        root.appendChild(video);

        requestAnimationFrame(() => {
            video.play();
        });
    }, [root, video]);

    useEffect(() => {
        const handler = () => {
            const duration = video.duration;
            const time = video.currentTime;
            if (duration - time < 0.2) {
                video.currentTime = 0;
                onVideoEnded();
            }
        };
        video.addEventListener('timeupdate', handler, false);

        return () => {
            video.removeEventListener('timeupdate', handler, false);
        };
    }, [video, onVideoEnded]);

    return (
        <div ref={containerRef}
             onContextMenu={onContextMenu}
             className={cn('canvas-video', className)}/>
    );
};

type Props = {
    video: HTMLVideoElement;
    className?: string;
    onVideoEnded: () => void;
}
