import React, { FC, RefObject, useEffect, useRef, useState, ReactDOM } from 'react';
import './menu.styl';
import cn from 'classnames';
import classNames from 'classnames';
import { VideoLink } from '../../pages/Welcome/VideoLink';
import { PageInfo } from '../../pages/Welcome/Welcome';
import { Link } from 'react-router-dom';
import requestAnimationFrameDebounce from '../../utils/requestAnimationFrameDebounce';
import { makeUrl } from '../../utils/makeUrl';


export const Menu: FC<Props> = ({ page, video, color, pages, activeVideoIndex, onHoverIn, onHoverOut, years, activeYear, playList }) => {

    const [bottom, setBottom] = useState<number>(0);
    const [translateX, setTranslateX] = useState<number>(0);
    const [menuWidth, setMenuWidth] = useState<number>(0);
    const [worksWidth, setWorksWidth] = useState<number>(0);
    const [menuRect, setMenuRect] = useState<DOMRect | null>(null);
    const [worksRect, setWorksRect] = useState<DOMRect | null>(null);
    const [menu, setMenuNode] = useState<HTMLDivElement | null>(null);
    const [works, setWorksNode] = useState<HTMLDivElement | null>(null);
    const refs = useRef<Array<RefObject<HTMLDivElement>>>(Array.from({length: pages.length}, a => useRef<HTMLDivElement>(null)));

    const worksRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!worksRef.current || !menuRef.current) {
            return void 0;
        }

        const works = worksRef.current;
        const menu = menuRef.current;

        setMenuNode(menu);
        setWorksNode(works);

        const onResize = requestAnimationFrameDebounce(() => {
            setMenuRect(menu.getBoundingClientRect());
            setWorksRect(works.getBoundingClientRect());
        });

        onResize();

        window.addEventListener('resize', onResize, false);
        return () => window.removeEventListener('resize', onResize, false);
    }, [worksRef.current, menuRef.current]);

    useEffect(() => {
        if (!page || !worksRect || !menuRect) {
            return void 0;
        }

        const worksLastChild = refs.current[refs.current.length-1].current;

        if (worksLastChild) {
            setWorksWidth(worksLastChild.getBoundingClientRect().right - worksRect.left);
        }

        const isMobile = window.innerWidth < 433;
        const cellW = window.innerWidth / 12 / (isMobile ? 2 : 1);
        const cellH = cellW * 0.4875;
        const cells = Math.floor(page.clientHeight / cellH);
        const extra = (page.clientHeight / cellH) - cells;
        const menuWidth = menuRect.width;
        const bottom = extra < 0.3 ? 0.3 * cellH : extra * cellH;

        const handler = requestAnimationFrameDebounce((e: MouseEvent) => {
            const mousePositionPercent = e.pageX / window.innerWidth;
            const extra = menuWidth - worksWidth;
            setTranslateX(extra * mousePositionPercent);
        });

        if (worksWidth <= menuWidth) {
            document.removeEventListener('mousemove', handler, false);
        } else {
            document.addEventListener('mousemove', handler, false);
        }

        setMenuWidth(menuWidth);
        setBottom(bottom);

        return () => {
            document.removeEventListener('mousemove', handler, false);
        }
    }, [page, menu, worksRect, menuRect, refs.current]);

    useEffect(() => {
        if (!menu || !works || menuWidth <= 0 || !menuRect || !worksRect) {
            return void 0;
        }

        const menuLeft = menuRect.left;
        const worksLeft = works.getBoundingClientRect().left;
        const activeNode = refs.current[activeVideoIndex].current;

        if (!activeNode) {
            return void 0;
        }

        const activeRect = activeNode.getBoundingClientRect();

        const activeRight = activeRect.right;
        const activeLeft = activeRect.left;

        if (activeRight > menuWidth + menuLeft) {
            setTranslateX(menuLeft - (activeRight - menuWidth + menuLeft - worksLeft));
        } else if (activeLeft < 0) {
            setTranslateX(0);
        }
    }, [activeVideoIndex, menu, works, menuWidth, menuRect, worksRect]);

    return (
        <div ref={menuRef} className={cn('menu', color)} style={{ 'bottom': `${bottom}px` }}>
            <div ref={worksRef} className={'menu__works'}
                 style={{ 'transform': `translateX(${translateX}px)` }}>
                {pages.map((item, index) =>
                    <VideoLink key={index}
                               video={video}
                               ref={refs.current[index]}
                               clickable={item.ready && (!!pages[index].youtubeVideoId || !!pages[index].youtubePlayListId)}
                               href={makeUrl({ year: activeYear, video: item.projectName, playList: playList })}
                               isActive={index === activeVideoIndex}
                               onHoverIn={onHoverIn}
                               onHoverOut={onHoverOut}
                               {...item}>
                        {pages[index].videoLinkText}</VideoLink>)
                }
            </div>
            <div className={'menu__years'}>
                {years.slice(0).reverse().map((year) => (
                    <Link key={year}
                          className={classNames('menu__link', { 'menu__link__active': year === activeYear })}
                          to={makeUrl({ year, playList: playList })}>
                        {year}
                    </Link>
                ))}
            </div>
        </div>
    );
};

type Props = {
    video: HTMLVideoElement;
    page: HTMLDivElement | null;
    color: string;
    pages: Array<PageInfo>;
    activeVideoIndex: number;
    onHoverIn: (index: number) => void;
    onHoverOut: (index: number) => void;
    years: Array<string>;
    activeYear: string;
    playList: string | undefined;
}
