import React, { FC } from 'react';
import './Info.styl';
import { Link } from 'react-router-dom';

export const Info: FC = () => (
        <Link to={"/contacts"}>
                <div className={"i-icon"}>
                    <svg  width="37px" height="37px" viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.6,13.5v18.3h-6V13.5H21.6z M18.6,4.4c-1.9,0-3.5,1.5-3.5,3.3s1.5,3.3,3.5,3.3S22,9.5,22,7.7
	S20.5,4.4,18.6,4.4z"/>
                    </svg>
                </div>
        </Link>
);

