export const debounce = <T extends Array<unknown>>(time: number, handler: (...args: T) => void): (...args: T) => void => {
    let timer: number | undefined = undefined;
    let activeArgs: T | undefined = undefined;

    return (...args) => {
        if (timer) {
            clearTimeout(timer);
        }
        activeArgs = args;

        timer = window.setTimeout(() => handler(...activeArgs as T), time);
    };
}

