import { PageInfo } from '../pages/Welcome/Welcome';
import { __, equals, gt, lt, where } from 'ramda';

export const getPage = (filter: 'next'|'previous'|'firstReady', index: number, pages: Array<PageInfo>): PageInfo | undefined => {
    if (filter === 'next') {
        return pages.find(where({
            ready: equals(true),
            index: gt(__, index)
        }));
    }
    if (filter === 'firstReady') {
        return pages.find(where({
            ready: equals(true),
            index: lt(__, index)
        }));
    }
    return pages.slice().reverse().find(where({
        ready: equals(true),
        index: lt(__, index)
    }));
};