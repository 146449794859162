import React, {
    FC,
    useCallback,
    useEffect,
    useState,
    forwardRef,
    ComponentPropsWithRef
} from 'react';
import cn from 'classnames';
import { VideoManagerStateItem } from '../../services/VideoManager';
import { addNamespace } from '../../helpers/addNamespace';
import { Link } from 'react-router-dom';


export const











    VideoLink = forwardRef<HTMLDivElement | null, ComponentPropsWithRef<FC<Props>>>(({ clickable, ready, index, onHoverIn, onHoverOut, isActive, children, href , video}, ref) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const [wasCalled, setWasCalled] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {

        console.log(isActive)

        let playing = true;

        const onProgress = () => {
            setProgress(video.currentTime/video.duration);
        };

        const loop = () => {
            onProgress();
            if (playing) {
                requestAnimationFrame(loop);
            }
        };

        loop();

        return () => {
            setProgress(0)
            playing = false;
        };
    }, [video]);

    const hoverInHandler = useCallback(() => {
        if (ready) {
            onHoverIn(index);
        } else {
            setHovered(true);
        }
    }, [setHovered, index, ready, onHoverIn]);

    const hoverOutHandler = useCallback(() => {
        if (ready) {
            onHoverOut(index);
        } else {
            setHovered(false);
        }
    }, [setHovered, index, ready, onHoverOut]);

    useEffect(() => {
        if (!ready) {
            return undefined;
        }
        if (wasCalled) {
            return undefined;
        }
        if (hovered) {
            onHoverIn(index);
        }
        setWasCalled(true);
    }, [ready, hovered, index, wasCalled, onHoverIn, setWasCalled]);

    return (
        <div ref={ref}
             style={isActive ? {backgroundSize: `${progress*100}% 100%`}: {backgroundSize : "0"}}
             className={addNamespace('menu', cn({
            'link__ready': ready,
            'link__active': isActive
        }, 'link'))}>
            {clickable ?
                <Link onMouseEnter={hoverInHandler}
                      onMouseLeave={hoverOutHandler}
                      to={href}>
                    {children}
                </Link>
                :
                <span onMouseEnter={hoverInHandler}
                      onMouseLeave={hoverOutHandler}>
                    {children}
                </span>
            }
        </div>
    );
});

type Props = {
    video: HTMLVideoElement
    clickable: boolean;
    index: number;
    isActive: boolean;
    href: string;
    onHoverIn: (index: number) => void;
    onHoverOut: (index: number) => void;
    className?: Parameters<typeof cn>;
} & VideoManagerStateItem;
