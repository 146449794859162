export default function(callback, noPrevent) {
    let waiting = false;
    let currentArgs;
    return (...args) => {
        if (!noPrevent && args[0] && args[0].preventDefault) {
            args[0].preventDefault();
        }
        if (!waiting) {
            currentArgs = args;
            requestAnimationFrame(() => {
                callback(...currentArgs);
                waiting = false;
            });
        } else {
            currentArgs = args;
        }
    };
}
