import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Logo } from '../../components/Logo/Logo';
import './contacts.styl';
import { dataModel } from '../../models/DataModel';
import { makeUrl } from '../../utils/makeUrl';

export const Contacts: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
    const [year, setYear] = useState<string>('20');
    const url = makeUrl({ year, playList: match.params.playListId });

    useEffect(() => {
        dataModel.getDataByYear('20')
            .then(({ years }) => {
                if (!years.includes(year)) {
                    setYear(years[years.length - 1]);
                }
            });
    }, []);

    return (
        <div className={'contacts'}>
            <Logo color={'dark'} href={url}/>
            <div className={'text'}>While working with us, the majority of our clients acquired leading positions in
                their categories. If you share our ambition for a strong emotional grip, creative excellence and visual
                perfection, please contact me
            </div>
            <div className={'address'}>
                <address>6 Place du Champ de Mars<br/>33000 Bordeaux, France
                </address>
                <a href={'tel:+33 06 29 94 34 97'}>+33 (0) 6 29 94 34 97</a><br/>
            </div>
            <a href={'mailto:hello@miguelivanov.com'} className={'mail'}>hello@miguelivanov.com</a>
        </div>
    );
};

type MatchParams = {
    playListId?: string;
}
