import { SHEET } from '../constants';

const DATA_DICTIONARY = {
    fileName: 'filename',
    projectName: 'project',
    year: 'YY',
    videoLinkText: 'menu',
    youtubeVideoId: 'YT',
    youtubePlayListId: 'YT Playlist',
    pageTitle: 'header',
    pageText: 'sub header',
    pageDateText: 'date',
    pageLinkUrl: 'url',
    facebookId: 'FB',
    playListId: 'PLAYLIST'
};

export class DataModel {

    private data: Array<Record<keyof typeof DATA_DICTIONARY, string>> = [];
    private ready: Promise<Array<Record<keyof typeof DATA_DICTIONARY, string>>>;

    constructor() {

        this.ready = this.load()
            .then(DataModel.remapRows)
            .then((data) => {
                this.data = data;
                return data;
            });
    }

    public getDataByYear(year = '20', playList: string | undefined = undefined): Promise<PageData> {
        return this.ready.then((data) => {
            return data.reduce<PageData>((acc, row) => {
                if (playList != undefined && row.playListId !== playList) {
                    return  acc;
                }

                if (row.year === year) {
                    acc.items.push(row);
                }

                if (row.year && !acc.years.includes(row.year)) {
                    acc.years.push(row.year);
                    acc.years = acc.years.sort((a, b) => Number(b) - Number(a));
                }

                return acc;
            }, { years: [], items: [] });
        });
    }

    private load(): Promise<any> {
        return fetch(SHEET)
            .then<SheetsResponse>((r) => r.json());
    }

    private static remapRows(data: SheetsResponse): Array<DataItem> {
        const headLine = data.values[0];
        const dictionary = Object.entries(DATA_DICTIONARY);

        const indexMap = headLine.reduce((acc: Record<string, number>, item: string, index: number) => {
            const res = dictionary.find(([key, value]) =>
                item.toLowerCase() === value.toLowerCase());

            if (res) {
                acc[res[0]] = index;
            }

            return acc;
        }, {});

        return data.values.slice(1).map((row: Array<string>) => Object.entries(indexMap).reduce((acc: any, [key, targetIndex]: [string, any]) => {
            acc[key] = row[targetIndex];
            return acc;
        }, {}));
    }
}

export type DataItem = Record<keyof typeof DATA_DICTIONARY, string>;
export type PageData = {
    items: Array<DataItem>;
    years: Array<string>;
}

type SheetsResponse = {
    range: string;
    majorDimension: string;
    values: Array<string[]>;
}

export const dataModel = new DataModel();
