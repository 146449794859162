import { propEq } from 'ramda';

export class Queue<T extends Record<string, any>> {

    private readonly maxLength: number;
    private readonly list: Array<QueueItem<T, keyof T>> = [];

    constructor(maxLen: number) {
        this.maxLength = maxLen;
    }

    public set<K extends keyof T>(name: K, value: T[K]): void {
        this.list.push({ name, value });
        if (this.list.length > this.maxLength) {
            this.list.splice(0, this.list.length - this.maxLength);
        }
    }

    public get<K extends keyof T>(name: K): T[K] | void {
        return this.list.find(propEq('name', name))?.value;
    }

}

type QueueItem<T extends Record<string, any>, K extends keyof T> = {
    name: K;
    value: T[K];
}
