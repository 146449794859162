import { VideoManager } from '../services/VideoManager';
import { DataItem, dataModel, PageData as YearData } from './DataModel';
import { Queue } from '../services/Queue';
import { IS_PHONE, YEARS_CACHE_COUNT } from '../constants';
import {propEq, filter} from "ramda";

const QUALITY = [
    'sd',
    'hd'
];

export class WelcomeModel {

    private memory: Queue<Record<string, VideoManager>> = new Queue<Record<string, VideoManager>>(YEARS_CACHE_COUNT);

    public loadVideo(manager: VideoManager, pageName: string, year = '20', playList: string | undefined = undefined): Promise<unknown>  {
        return dataModel.getDataByYear(year, playList)
            .then(data => {
                const project:Array<DataItem> = filter(propEq('projectName', pageName), data.items);
                return manager.loadOne(project[0].fileName);
            })
    }

    public openPage(year = '20', playList: string | undefined = undefined, orientation: 'horizontal' | 'vertical'): Promise<PageData> {
        return dataModel.getDataByYear(year, playList)
            .then(data => {
                const manager = this.getVideoManager(year, data, orientation);
                return {
                    manager,
                    meta: data.items,
                    years: data.years
                };
            });
    }

    private getVideoManager(year: string, data: YearData, orientation: 'horizontal' | 'vertical'): VideoManager {
        let manager: void | VideoManager = this.memory.get(year);
        if (manager) {
            return manager;
        }
        manager = new VideoManager(QUALITY, data.items.map(item => item.fileName), year, orientation, IS_PHONE);
        this.memory.set(year, manager);
        return manager;
    }

}

export type PageData = {
    manager: VideoManager;
    meta: Array<DataItem>;
    years: Array<string>;
}

export const welcomeModel = new WelcomeModel();
