export class HistoryList<T> {

    private readonly maxLength: number;
    private readonly history: Array<T> = [];

    public get length(): number {
        return this.history.length;
    }

    constructor(maxLength: number) {
        this.maxLength = maxLength;
    }

    public push(item: T): void {
        this.history.push(item);
        const toRemove = this.history.length - this.maxLength;

        if (toRemove > 0) {
            this.history.splice(0, toRemove);
        }
    }

    public has(element: T): boolean {
        return this.history.includes(element);
    }

    public last(): T | undefined {
        return this.get(this.length - 1);
    }

    public getPrevious(): T | undefined {
        return this.get(this.length - 2);
    }

    public get(index: number): T | undefined {
        return this.history[index];
    }
}