import React, { FC } from 'react';
import { BrowserRouterProps, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { Welcome } from './pages/Welcome/Welcome';
import { Contacts } from './pages/Contacts/Contacts';
import { RouteComponentProps } from 'react-router';
import { makeUrl } from './utils/makeUrl';

const year = ':year';
const video = ':video';
const playList = ':playListId';

export const App = withRouter<RouteComponentProps, FC<AppProps>>(() => (
    <Switch>
        <Route exact path={'/contacts'} component={Contacts}/>
        <Route exact path={'/contacts/playList/:playListId'} component={Contacts}/>
        <Route exact path={makeUrl()} component={Welcome}/>
        <Route exact path={makeUrl({ playList })} component={Welcome}/>
        <Route exact path={makeUrl({ year })} component={Welcome}/>
        <Route exact path={makeUrl({ year, playList })} component={Welcome}/>
        <Route exact path={makeUrl({ year, video })} component={Welcome}/>
        <Route exact path={makeUrl({ year, video, playList })} component={Welcome}/>
        <Redirect to={makeUrl()}/>
    </Switch>
));

type AppProps = BrowserRouterProps & RouteComponentProps;


