import React, { FC, useCallback, useEffect, useState } from 'react';
import './project.styl';
import YouTube, { PlayerVars } from 'react-youtube';
import { EmbeddedPost, FacebookProvider } from 'react-facebook';
import { debounce } from '../../utils/debounce';
import { toggleBg } from '../../utils/toggleBg';


export const Project: FC<{ title: string, text: string, fb: string, date: string, ytid: string, ytplid: string, linkUrl: string }> = ({ title, text, ytid, ytplid, fb = null, date = null, linkUrl = null }) => {

    const [cellW, setCellW] = useState<number>(window.innerWidth > 980 ? window.innerWidth / 12 : window.innerWidth / 6);

    useEffect(() => {
        const resizeHandler = debounce(600, () => setCellW(window.innerWidth / 12));

        window.addEventListener('resize', resizeHandler, false);
        return () => {
            window.removeEventListener('resize', resizeHandler, false);
        };
    }, [setCellW]);

    useEffect(() => toggleBg('block'), []);

    const onYTPlayerReady = useCallback(toggleBg('none'), []);

    const PlayerVars: PlayerVars = ytplid ? {
        listType: 'playlist',
        list: ytplid
    } : {};


    return (
        <div className={'info'}>
            <YouTube className={'ytp'}
                     videoId={ytid}
                     containerClassName={'ytp-wrap'}
                     onReady={onYTPlayerReady}
                     opts={{
                         playerVars: {
                             autoplay: 1,
                             modestbranding: 1,
                             playsinline: 0,
                             rel: 0,
                             showinfo: 0,
                             ...PlayerVars
                         }
                     }}/>
            <h1>{title}</h1>
            <div className={'row'}>
                <div className={'col-left'}>
                    {date ? <div className={'release-date'}>{date}</div> : null}
                    <div className={'release-text'}>
                        {text ? <span>{text} </span> : null}
                        {linkUrl ? <a href={linkUrl} rel="noopener noreferrer" target={'_blank'} className={'release-link'}>подробнее</a> : null}
                    </div>
                </div>
                {fb ?
                    <div className={'col-right'}>
                        <FacebookProvider appId={340460190499582}>
                            <EmbeddedPost key={cellW} href={fb}/>
                        </FacebookProvider>
                    </div>
                    : null
                }
            </div>
        </div>
    );
};

